import { useEffect } from "react"
import { useFormik } from "formik"
import { toast } from "react-toastify"
import { isEmpty } from "lodash"

import { BaseModal, Button, Dropdown, Input } from "../common"
import { newLandingPageSchema } from "../../helpers/validationSchema"
import { createLandingPagesAPI, updateLandingPagesAPI } from "../../service"


export const CreateLandingPageModal = ({ open, setOpen, landing, verticals, getAllLandingPages, getLandingPageNotifications }) => {

    const submitLandingPageHandler = async ({ name, url, offer }) => {
        try {
            if (!offer) {
                toast.error("Please select an offer")
                return
            }

            if (isEmpty(landing)) {
                await createLandingPagesAPI({ name, url, offer })
                toast.success("Landing Page Created!")
            } else {
                await updateLandingPagesAPI(landing.id, { name, url, offer })
                toast.success("Landing Page Updated!")
            }

            getAllLandingPages()
            getLandingPageNotifications()

            formik.resetForm()
            setOpen(false)
        } catch (error) {
            const error_response = error?.response.data
            let message = 'Something went wrong';
            if (error_response) {
                if (typeof error_response === 'object' && !Array.isArray(error_response)) {
                    message = Object.values(error_response).flat().join(' ')
                } else if (typeof error_response === 'object' && Array.isArray(error_response)) {
                    message = error_response.join(' ')
                } else if (typeof error_response === 'string') {
                    message = error_response
                }
            }
            toast.error(message)
        } finally {
            console.log('finally')
            formik.setSubmitting(false)
        }
    }

    const formik = useFormik({
        initialValues: { name: "", url: "", offer: "" },
        validationSchema: newLandingPageSchema,
        onSubmit: submitLandingPageHandler
    })

    useEffect(() => {
        if (landing) {
            formik.setFieldValue("name", landing.name)
            formik.setFieldValue("url", landing.url)
            formik.setFieldValue("offer", landing.offer && landing.offer.id)
        } else {
            formik.setFieldValue("name", "")
            formik.setFieldValue("url", "")
            formik.setFieldValue("offer", "")
        }
    }, [landing])

    return (
        <BaseModal
            open={open}
            setOpen={setOpen}
            title={isEmpty(landing) ? "Create Landing Page" : "Update Landing Page"}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="flex flex-col w-full">
                    <div className="mt-1">
                        <Input
                            placeholder="Landing Page Name"
                            name="name"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                            error={formik.errors.name}
                            touched={formik.touched.name}
                        />
                    </div>
                    <div className="mt-1">
                        <Input
                            placeholder="URL"
                            name="url"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.url}
                            error={formik.errors.url}
                            touched={formik.touched.url}
                        />
                    </div>
                    <div className="mt-1">
                        <Dropdown
                            placeholder="Associate Offer"
                            name={`offer`}
                            onChange={e => formik.setFieldValue("offer", e.value)}
                            options={verticals}
                            value={formik.values.offer}
                        />
                    </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row justify-end">
                    <Button
                        type="submit"
                        className={"bg-[#B6CC43] mr-2 py-2 px-3 shadow-sm"}
                    >
                        {isEmpty(landing) ? "+ Create" : "Update"}
                    </Button>
                    <Button
                        type="button"
                        className={"bg-[#CC4B43] mr-2 py-2 px-3 shadow-sm"}
                        onClick={() => setOpen(false)}
                    >
                        Close
                    </Button>
                </div>
            </form>
        </BaseModal>
    )
}