import React, { Suspense, useContext, useEffect, useState } from "react"
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "react-confirm-alert/src/react-confirm-alert.css"
import "rsuite/dist/rsuite.min.css"

import { getProfileAPI } from "./service"
import { ProfileContext } from "./context/profileContext"
import { DashboardLayout } from "./components"
import moment from "moment/moment";
import { authenticatedRoutes, guestRoutes } from "./routes/routes";
import { pushUserDataToDataLayer } from "./helpers";

function App() {
    const token = localStorage.getItem("token")
    const [_, setProfileData] = useContext(ProfileContext)
    const [isAuth, setAuth] = useState(!!token)

    useEffect(() => {
        moment.tz.setDefault("America/New_York")
    }, [])

    const getProfileData = async () => {
        try {
            const { data: user } = await getProfileAPI()
            setProfileData({ ...user, loaded: true })
            pushUserDataToDataLayer(user)
            setAuth(true)
        } catch (error) {
            setAuth(false)
        }
    }

    useEffect(() => {
        const token = localStorage.getItem("token")
        if (token) {
            getProfileData()
        } else {
            setAuth(false)
        }
    }, [])

    return (
        <>
            <ToastContainer/>
            <Suspense fallback={<div>Loading...</div>}>
                <Router>
                    <Routes>
                        {guestRoutes.map((route, index) => (
                            <Route key={index} path={route.path} element={
                                <Guest isAuth={isAuth}>
                                    {route.component}
                                </Guest>
                            }/>
                        ))}

                        <Route path="/dashboard" element={<DashboardLayout/>}>
                            {authenticatedRoutes.map((route, index) => (
                                <Route
                                    key={index}
                                    path={route.path}
                                    element={
                                        <Protected isAuth={isAuth}>
                                            {route.component}
                                        </Protected>
                                    }
                                />
                            ))}
                        </Route>

                        {/*<Route path="/admin" element={<AdminLayout/>}>*/}
                        {/*    <Route path="login" element={<AdminLogin/>}/>*/}
                        {/*    <Route path="users" element={<Users/>}/>*/}
                        {/*    <Route path="feedback" element={<Feedback/>}/>*/}
                        {/*</Route>*/}

                        <Route path="*" element={<Navigate replace to="/login"/>}/>
                    </Routes>
                </Router>
            </Suspense>
        </>
    )
}

export default App

function Protected({ children, isAuth = true }) {
    let token = localStorage.getItem("token")

    // if (!isAuth) {
    //     return <Navigate to="/login" replace/>
    // }

    if (!token) {
        return <Navigate to="/login" replace/>
    }
    return children
}

function Guest({ children, isAuth = false }) {
    if (isAuth) {
        return <Navigate to="/dashboard/campagins" replace/>
    }

    return children
}
