import { useEffect, useState } from "react";
import { findIndex } from "lodash";
import { BaseModal, Button, Checkbox } from "../../common";

const ManageDashboardDataTableColumnsModal = ({ open = false, setOpen, allColumns, setColumns }) => {
    const [currentColumns, setCurrentColumns] = useState([])

    const handleSave = () => {
        setColumns(currentColumns)
        setOpen(false)
    }

    useEffect(() => {
        setCurrentColumns(allColumns)
    }, [open, allColumns])

    const handleChange = ({ target: { name, value } }) => {
        const tempColumns = [...currentColumns]

        let index = findIndex(tempColumns, { value: name })

        tempColumns[index] = {
            ...tempColumns[index],
            isActive: !tempColumns[index].isActive
        }
        setCurrentColumns(tempColumns)
    }

    return (
        <BaseModal open={open} setOpen={setOpen} title={"Manage Columns"}>
            <div className="border rounded-lg flex-col">
                <div className="border-b-2 w-full pl-5 py-2 text-sm">
                    Select columns to manage{" "}
                </div>
                <div className="grid grid-cols-2 gap-4 px-4 py-4">
                    {currentColumns.map(({ label, value, isActive }) => (
                        <Checkbox
                            title={label}
                            name={value}
                            checked={!isActive}
                            onChange={handleChange}
                        />
                    ))}
                </div>
            </div>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row justify-end">
                <Button
                    type="button"
                    className={"bg-[#CC4B43] mr-2 py-2 px-3 shadow-sm"}
                    onClick={() => {
                        setOpen(false)
                    }}
                >
                    Close
                </Button>
                <Button
                    type="button"
                    className={"bg-[#B6CC43] mr-2 py-2 px-3 shadow-sm"}
                    onClick={handleSave}
                >
                    Save
                </Button>
            </div>
        </BaseModal>
    )
}

export default ManageDashboardDataTableColumnsModal