import { useEffect, useState } from "react";
import { assignVerticalToCampagins, getVerticalsAPI } from "../../../service";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { assignVerticalToCampaginsSchema } from "../../../helpers/validationSchema";
import { BaseModal, Button, Dropdown } from "../../common";

const AssignedVerticalsToCampaignsModals = ({ open = false, setOpen, vertical: currentVertical = {}, loadData }) => {
    const [verticals, setVerticals] = useState([])

    useEffect(() => {
        getAllVerticals()
    }, [])

    const getAllVerticals = async () => {
        try {
            const {
                data: data
            } = await getVerticalsAPI()

            const reArrangedFiltered = data.map(({ id, name }) => ({
                label: name,
                value: id
            }))
            setVerticals(reArrangedFiltered)
        } catch (error) {
            const message =
                (error?.response.data?.non_field_errors &&
                    error?.response.data?.non_field_errors[0]) ||
                "something went wrong"
            console.log("error?.response?", error.response)
            console.log("ERROR", error)
            toast.error(message)
        }
    }

    const submitHandler = async ({ vertical }) => {
        try {
            await assignVerticalToCampagins(currentVertical.id, {
                verticals: [vertical.value]
            })
            toast.success("Vertical assigned")
            formik.resetForm()
            setOpen(false)
            loadData()
        } catch (error) {
            const message =
                (error?.response.data?.non_field_errors &&
                    error?.response.data?.non_field_errors[0]) ||
                "something went wrong"
            console.log("error?.response?", error.response)
            console.log("ERROR", error)
            toast.error(message)
        } finally {
            formik.setSubmitting(false)
        }
    }

    const formik = useFormik({
        initialValues: {
            vertical: {}
        },
        validationSchema: assignVerticalToCampaginsSchema,
        onSubmit: submitHandler
    })

    return (
        <BaseModal open={open} setOpen={setOpen} title={"Assign Verticals"}>
            <form onSubmit={formik.handleSubmit}>
                <div className="border rounded-lg flex-col">
                    <div className="border-b-2 w-full pl-5 py-2 text-sm">Verticals</div>
                    <div className="grid grid-cols-1 gap-x-4 px-4 py-4">
                        <Dropdown
                            options={verticals}
                            placeholder="verticals"
                            onChange={value => formik.setFieldValue("vertical", value)}
                            name="vertical"
                            onBlur={formik.handleBlur}
                            value={formik.values.vertical}
                        />
                        {formik.errors.vertical && formik.values.vertical && (
                            <p className="text-red-600">Please select a vertical</p>
                        )}
                    </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row justify-end">
                    <Button
                        type="button"
                        className={"bg-[#CC4B43] mr-2 py-2 px-3 shadow-sm"}
                        onClick={() => {
                            setOpen(false)
                        }}
                    >
                        Close
                    </Button>
                    <Button
                        type="submit"
                        className={"bg-[#B6CC43] mr-2 py-2 px-3 shadow-sm"}
                    >
                        Save
                    </Button>
                </div>
            </form>
        </BaseModal>
    )
}


export default AssignedVerticalsToCampaignsModals