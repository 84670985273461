import { useState } from "react"
import Select from "./select"
import { FaTimes } from "react-icons/fa"

function classNames(...classes) {
    return classes.filter(Boolean).join(" ")
}

export default function Input({
                                  label,
                                  Icon,
                                  className,
                                  error,
                                  touched,
                                  iconClick,
                                  type = "text",
                                  placeholder = "",
                                  iconClasses = "",
                                  showDropDown = false,
                                  handleDropDown,
                                  onRemove,
                                  value,
                                  ...props
                              }) {
    const [showPassword, setShowPassword] = useState(false)
    const [rangeValue, setRangeValue] = useState(30)
    return (
        <div>
            {label && (
                <label className="block text-sm font-medium text-gray-700 mt-3">
                    {label}
                </label>
            )}
            <div className="relative mt-1 rounded-md shadow-sm">
                <input
                    type={
                        type === "password" && !Icon ? type : showPassword ? "text" : type
                    }
                    className={
                        `bg-[#F4F4F4] p-2 placeholder:text-black placeholder:opacity-70 placeholder:font-light my-3 block w-full rounded-md border-gray-300 pr-1 focus:border-[#B6CC43] focus:ring-[#B6CC43] sm:text-sm ` +
                        (className || '') +
                        (label ? 'mt-0' : '')
                    }
                    placeholder={placeholder}
                    value={value}
                    {...props}
                />
                {/* {showDropDown && (
          <Menu
            as="div"
            className="absolute inset-y-0 flex items-center left-72"
          >
            <div>
              <Menu.Button className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none">
                <FaAngleDown className="h-5 w-5" aria-hidden="true" />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2 w-80 origin-bottom-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="p-4">
                  <Menu.Item>
                    <div className="grid grid-cols-4 gap-2 flex items-center">
                      <Slider
                        className="col-span-3"
                        defaultValue={30}
                        min={10}
                        max={50}
                        onChange={value => {
                          setRangeValue(value)
                        }}
                      />
                      <InputNumber
                        className="h-10"
                        progress
                        min={10}
                        max={50}
                        value={rangeValue}
                        onChange={value => {
                          setRangeValue(value)
                        }}
                      />
                    </div>
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        )} */}
                {Icon && (
                    <div
                        className={iconClasses}
                        onClick={() => setShowPassword(old => !old)}
                    >
                        <Icon/>
                    </div>
                )}
                {showDropDown && (
                    <div className="absolute inset-y-0 right-8 flex items-center">
                        <Select
                            className="flex"
                            options={[
                                { value: "include", label: "include" },
                                { value: "exclude", label: "exclude" }
                            ]}
                            islocationField={true}
                            setSelected={handleDropDown}
                            // selected={{value: 'include', label: 'include'}}
                        />
                    </div>
                )}
                {onRemove && (
                    <div
                        className="absolute inset-y-0 flex p-3 cursor-pointer right-0"
                        onClick={() => onRemove(value)}
                    >
                        <FaTimes/>
                    </div>
                )}
            </div>
            {error && touched && <p className="text text-red-500">{error}</p>}
        </div>
    )
}
