import React from "react"
import ReactDOM from "react-dom/client"
// ** Redux Imports
import { Provider } from 'react-redux'
import { store } from './redux/storeConfig/store'
import "./index.css"
import App from "./App"
import { ProfileContextProvider } from "./context/profileContext"
// import * as Sentry from "@sentry/react";
//
// Sentry.init({
//     dsn: "https://4b0e79d71d033177b6022b1f6e80a765@o4507628441501696.ingest.us.sentry.io/4507628453036032",
//
//     // This sets the sample rate to be 10%. You may want this to be 100% while
//     // in development and sample at a lower rate in production
//     replaysSessionSampleRate: 1.0,
//
//     // If the entire session is not sampled, use the below sample rate to sample
//     // sessions when an error occurs.
//     replaysOnErrorSampleRate: 1.0,
//
//     integrations: [
//         Sentry.replayIntegration({
//             // Additional SDK configuration goes in here, for example:
//             maskAllText: true,
//             blockAllMedia: true,
//         }),
//     ],
// });


const root = ReactDOM.createRoot(document.getElementById("root"))

root.render(
    <Provider store={store}>
        <ProfileContextProvider>
            <App/>
        </ProfileContextProvider>
    </Provider>
)
