import { useEffect, useState } from "react";
import { addTestModule, addTestPhase } from "../../service";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { BaseModal, Dropdown, Input, Spinner, Textarea } from "../common";
import { useNavigate } from "react-router-dom";
import { DEFINED_KPIS, TESING_VARIABLES } from "../../utils/constants";

export const TestSopModal = ({ openSetup, setOpenSetup, openSOP, setOpenSOP, openConfig, setOpenConfig, setTotalPhases, setTestModuleId }) => {

    const [loading, setLoading] = useState(false)

    const submitHandler = async ({ total_phases }) => {
        try {
            setLoading(true)
            const { data } = await addTestModule({ total_phases: total_phases })
            setTotalPhases(total_phases)
            setTestModuleId(data.id)
            formik.resetForm()
            setOpenSetup(false)
            setOpenSOP(false)
            setOpenConfig(true)
            setLoading(false)
        } catch (error) {
            const message =
                (error?.response.data?.non_field_errors &&
                    error?.response.data?.non_field_errors[0]) ||
                "something went wrong"
            toast.error(message)
        } finally {
            formik.setSubmitting(false)
            setLoading(false)
        }
    }

    const formik = useFormik({
        initialValues: {
            total_phases: 1
        },
        onSubmit: submitHandler
    })

    return (
        <BaseModal
            open={openSOP}
            setOpen={setOpenSOP}
            title={"How many variables would you like to test?"}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="flex flex-col w-full">
                    <div className="mt-1 flex flex-row items-center py-3">
                        <label className="font-semibold text-sm">Number of Phases</label>
                        <div className="ml-4 w-[150px]">
                            <Input
                                name="total_phases"
                                placeholder="1"
                                type="number"
                                min={0}
                                onChange={(e) => formik.setFieldValue("total_phases", e.target.value)}
                                onBlur={formik.handleBlur}
                                value={formik.values.total_phases}
                                error={formik.errors.total_phases}
                                touched={formik.touched.total_phases}
                            />
                        </div>
                    </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row justify-between">
                    <button
                        className="block w-full md:inline-block md:w-auto px-12 py-3 bg-[#B6CC43] rounded-xl mr-3 text-white hover:text-white hover:no-underline font-semibold text-sm"
                        type="submit"
                    >
                        {(loading) ? <Spinner className="w-5 h-5"/> : 'Next'}
                    </button>
                    <button
                        className="block w-full md:inline-block md:w-auto px-12 py-3 bg-[#B6CC43] rounded-xl mr-3 text-white hover:text-white hover:no-underline font-semibold text-sm"
                        type="button"
                        onClick={() => {
                            setOpenSetup(true);
                            setOpenSOP(false);
                            setOpenConfig(false);
                        }}
                    >
                        Back
                    </button>
                </div>
            </form>
        </BaseModal>
    )
}

export const TestConfigModal = ({
                                    title,
                                    openConfig,
                                    setOpenConfig,
                                    openSOP,
                                    setOpenSOP,
                                    openSaveTemplate,
                                    setOpenSaveTemplate,
                                    saveTemplate,
                                    setSaveTemplate,
                                    testModuleId,
                                    winnerAds,
                                    setWinnerAds
                                }) => {

    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()

    const submitHandler = async (values) => {
        try {
            const params = {
                test_module: testModuleId,
                variable: values.variable.value,
                limit_days: (values.length_of_test && values.length_of_test.value === "Length of Time") ? values.limit_days : null,
                limit_spend: (values.length_of_test && values.length_of_test.value === "Money Spent") ? values.limit_spend : null,
                limit_impressions: (values.length_of_test && values.length_of_test.value === "Impressions") ? values.limit_impressions : null,
                winning_kpi: values.winning_kpi.value,
                winning_operator: values.winning_operator.value,
                winning_threshold: values.winning_threshold,
                notes: values.notes
            }
            if (winnerAds) {
                params.winner_ad_ids = winnerAds
            }
            setLoading(true)
            const { data } = await addTestPhase(params)

            formik.resetForm()
            setLoading(false)
            setWinnerAds([])
            setOpenConfig(false);
            setOpenSOP(false);
            setOpenSaveTemplate(true);
            setSaveTemplate(false)

            if (data && data.meta_campaign && data.meta_campaign.id) {
                navigate(`/dashboard/campagins-structure/${data.meta_campaign.id}`)
            } else {
                navigate(`/dashboard/campagins-structure/?testPhaseId=${data.id}`)
            }
        } catch (error) {
            const message =
                (error?.response.data?.non_field_errors &&
                    error?.response.data?.non_field_errors[0]) ||
                "something went wrong"
            toast.error(message)
            setLoading(false)
        } finally {
            formik.setSubmitting(false)
            setLoading(false)
        }
    }

    const formik = useFormik({
        initialValues: {
            test_module: "",
            variable: "",
            length_of_test: "",
            winning_kpi: "",
            limit_days: "",
            limit_spend: "",
            limit_impressions: "",
            start_date: "",
            end_date: "",
            notes: ""
        },
        onSubmit: submitHandler
    })

    useEffect(() => {

    }, [])

    const operatorData = [
        { label: "<", value: "<" },
        { label: ">", value: ">" },
        { label: "<=", value: "<=" },
        { label: ">=", value: ">=" },
        { label: "=", value: "=" }
    ]

    return (
        <BaseModal
            open={openConfig}
            setOpen={setOpenConfig}
            title={title}
            modalSize="max-w-screen-md"
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="grid grid-cols-2 gap-4 w-full mt-8">
                    <div className="block">
                        <p className="font-semibold mb-1">Select Variable</p>
                        <Dropdown
                            placeholder="Select"
                            options={TESING_VARIABLES}
                            name="variable"
                            onChange={value => formik.setFieldValue("variable", value)}
                            onBlur={formik.handleBlur}
                            value={formik.values.variable}
                            error={formik.errors.variable}
                            touched={formik.touched.variable}
                        />
                    </div>
                    <div className="block">
                        <p className="font-semibold mb-1">Length Of Test</p>
                        <Dropdown
                            placeholder="Select"
                            options={[
                                { label: "Length of Time", value: "Length of Time" },
                                { label: "Money Spent", value: "Money Spent" },
                                { label: "Impressions", value: "Impressions" }
                            ]}
                            name="length_of_test"
                            onChange={value => formik.setFieldValue("length_of_test", value)}
                            onBlur={formik.handleBlur}
                            value={formik.values.length_of_test}
                            error={formik.errors.length_of_test}
                            touched={formik.touched.length_of_test}
                        />
                    </div>
                    <div className="block">
                        <p className="font-semibold mb-1">Define Winning KPI</p>
                        <Dropdown
                            placeholder="Select"
                            options={DEFINED_KPIS}
                            name="winning_kpi"
                            onChange={value => formik.setFieldValue("winning_kpi", value)}
                            onBlur={formik.handleBlur}
                            value={formik.values.winning_kpi}
                            error={formik.errors.winning_kpi}
                            touched={formik.touched.winning_kpi}
                        />
                    </div>

                    {(formik.values.length_of_test && formik.values.length_of_test.value === "Length of Time") && <>
                        <div className="block">
                            <p className="font-semibold">Number of Days</p>
                            <Input placeholder="Number of Days" type="number" name="limit_days"
                                   onChange={formik.handleChange}
                                   onBlur={formik.handleBlur}
                                   value={formik.values.limit_days}
                                   error={formik.errors.limit_days}
                                   touched={formik.touched.limit_days}
                            />
                        </div>
                    </>}

                    {(formik.values.length_of_test && formik.values.length_of_test.value === "Money Spent") && <>
                        <div className="block">
                            <p className="font-semibold">Money Spent</p>
                            <Input placeholder="Money Spent" name="limit_spend"
                                   onChange={(e) => formik.setFieldValue("limit_spend", e.target.value)}
                                   onBlur={formik.handleBlur}
                                   value={formik.values.limit_spend}
                                   error={formik.errors.limit_spend}
                                   touched={formik.touched.limit_spend}
                            />
                        </div>
                    </>}

                    {(formik.values.length_of_test && formik.values.length_of_test.value === "Impressions") && <>
                        <div className="block">
                            <p className="font-semibold">Impressions</p>
                            <Input placeholder="Impressions" name="limit_impressions"
                                   onChange={(e) => formik.setFieldValue("limit_impressions", e.target.value)}
                                   onBlur={formik.handleBlur}
                                   value={formik.values.limit_impressions}
                                   error={formik.errors.limit_impressions}
                                   touched={formik.touched.limit_impressions}
                            />
                        </div>
                    </>}
                </div>
                <div className="grid grid-cols-2 gap-4 w-full mt-4 mb-8">
                    <div className="block">
                        <p className="font-semibold mb-1">Winning Operator</p>
                        <Dropdown
                            placeholder="Select"
                            options={operatorData}
                            name="winning_operator"
                            onChange={value => formik.setFieldValue("winning_operator", value)}
                            value={formik.values.winning_operator}
                            onBlur={formik.handleBlur}
                            error={formik.errors.winning_operator}
                            touched={formik.touched.winning_operator}
                        />
                    </div>
                    <div className="block">
                        <p className="font-semibold mb-1">Winning Threshold</p>
                        <Input placeholder="Winning Threshold"
                               name="winning_threshold"
                               onChange={(e) => formik.setFieldValue("winning_threshold", e.target.value)}
                               onBlur={formik.handleBlur}
                               value={formik.values.winning_threshold}
                               error={formik.errors.winning_threshold}
                               touched={formik.touched.winning_threshold}
                        />
                    </div>
                    <div className="block col-span-2">
                        <p className="font-semibold mb-1">Notes</p>
                        <Textarea
                            placeholder="Notes"
                            className="mt-0"
                            name="notes"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.notes}
                            error={formik.errors.notes}
                            touched={formik.touched.notes}
                        />
                    </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row justify-between">
                    <button
                        className="block w-full md:inline-block md:w-auto px-12 py-3 bg-[#B6CC43] rounded-xl mr-3 text-white hover:text-white hover:no-underline font-semibold text-sm"
                        type="submit"
                    >
                        {(loading) ? <Spinner className="w-5 h-5"/> : 'Next'}
                    </button>
                    <button
                        className="block w-full md:inline-block md:w-auto px-12 py-3 bg-[#B6CC43] rounded-xl  text-white hover:text-white hover:no-underline font-semibold text-sm"
                        type="button"
                        onClick={() => {
                            setOpenConfig(false);
                            setOpenSOP(true);
                            setOpenSaveTemplate(false)
                        }}
                    >
                        Back
                    </button>
                </div>
            </form>
        </BaseModal>
    )
}