import { AiFillHome, AiOutlineFacebook, AiOutlineGoogle } from "react-icons/ai";
import { TbSpeakerphone } from "react-icons/tb";
import { Automations, Vertical } from "../icons";
import { BiListCheck } from "react-icons/bi";
import { MdOutlineFormatListBulleted, MdOutlineLightbulb } from "react-icons/md";
import { FaFileInvoice } from "react-icons/fa";
import { IoIosSettings } from "react-icons/io";

export const navigation = [
    {
        isLink: true,
        name: "Dashboard",
        href: "/dashboard",
        Icon: AiFillHome
    },

    {
        isLink: true,
        name: "Drafts",
        href: "/dashboard/drafts",
        Icon: TbSpeakerphone,
        subMenu: [
            {
                title: "Facebook",
                Icon: AiOutlineFacebook,
                links: [
                    {
                        name: "Campaign",
                        href: "/dashboard/campagins"
                    }
                ]
            },
            {
                title: "Ad Words",
                Icon: AiOutlineGoogle,
                links: [
                    {
                        name: "Campaign",
                        href: "/dashboard/adwords"
                    }
                ]
            }
        ]
    },
    {
        isLink: true,
        name: "Automation",
        href: "/dashboard/automation",
        Icon: Automations
    },
    {
        isLink: true,
        name: "Offers",
        href: "/dashboard/offers",
        Icon: Vertical
    },
    {
        isLink: true,
        name: "Landing Page",
        href: "/dashboard/landing-page",
        Icon: BiListCheck
    },
    {
        isLink: true,
        name: "Creative Studio",
        href: "/dashboard/creative-studio",
        Icon: MdOutlineLightbulb
    },
    {
        isLink: true,
        name: "Testing",
        href: "/dashboard/testing",
        Icon: MdOutlineFormatListBulleted
    },
    {
        isLink: true,
        name: "Logs",
        href: "/dashboard/logs",
        Icon: FaFileInvoice
    },
    {
        isLink: true,
        name: "Settings",
        href: "/dashboard/settings",
        Icon: IoIosSettings
    }
]
