export default function Checkbox({ title, ...props }) {
    return (
        <div className="relative flex items-start">
            <label>
                <div className="flex h-6 items-center">
                    <input
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-[#B6CC43] focus:ring-[#B6CC43]"
                        {...props}
                    />

                    <span className="font-medium text-gray-900 pl-2">
                  {title}
                </span>
                </div>
                <div className="ml-3 text-sm leading-6">

                </div>
            </label>
        </div>
    )
}
