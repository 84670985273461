import { useState } from "react"
import { useFormik } from "formik"
import { toast } from "react-toastify"

import { BaseModal, Button } from "../../common"
import { createCampaignAPI } from "../../../service"
import {
  CampaignStructure,
  CampaignData,
  GoalsTracking,
  AudienceTargeting,
  AdCreative
} from "./formSteps"

export default function CreateCompagins({ open, setOpen, loadData }) {
  const [currentStep, setCurrentStep] = useState(0)
  const [campaignData, setCampaignsData] = useState({})

  const submitHandler = async ({
    budget_criteria: { label: budget_criteria },
    category: { label: category },
    objective: { label: objective },
    strategy_type: { label: strategy_type },
    facebook: { label: facebook },
    instagram: { label: instagram },
    attribution: { label: attribution },
    performance_goals: { label: performance_goals },
    age_from: { label: age_from = "age_from" },
    age_to: { label: age_to = "age_to" },
    start_date,
    end_date,
    ...values
  }) => {
    if (currentStep < 2) return
    try {
      console.log({
        category: parseInt(category),
        objective: parseInt(objective),
        budget_criteria,
        strategy_type,
        facebook,
        instagram,
        attribution,
        performance_goals,
        age_from,
        age_to,
        start_date: new Date(start_date),
        end_date: new Date(start_date),
        ...values
      })
      const response = await createCampaignAPI({
        category: parseInt(category),
        objective: parseInt(objective),
        budget_criteria,
        strategy_type,
        facebook,
        instagram,
        attribution,
        performance_goals,
        age_from,
        age_to,
        start_date: new Date(start_date),
        end_date: new Date(start_date),
        ...values
      })
      setOpen(false)
      loadData()
      toast.success("Campaign Created")
      console.log("response", response)
    } catch (error) {
      const message =
        (error?.response.data?.non_field_errors &&
          error?.response.data?.non_field_errors[0]) ||
        "something went wrong"
      console.log("error?.response?", error.response)
      console.log("ERROR", error)
      toast.error(message)
    } finally {
    }
  }

  const handleFormsData = values => {
    setCampaignsData({ ...campaignData, ...values })
    console.log("campaignData, ...values", { ...campaignData, ...values })
    if (currentStep === 4) submitHandler({ ...campaignData, ...values })
    else setCurrentStep(currentStep + 1)
  }

  return (
    <BaseModal open={open} setOpen={setOpen} className="sm:max-w-2xl">
      <ProgressBar totalSteps={5} currentStep={currentStep} />
      {currentStep === 0 && <CampaignStructure submitData={handleFormsData} />}
      {currentStep === 1 && <CampaignData submitData={handleFormsData} />}
      {currentStep === 2 && <GoalsTracking submitData={handleFormsData} />}
      {currentStep === 3 && <AudienceTargeting submitData={handleFormsData} />}
      {currentStep === 4 && <AdCreative submitData={handleFormsData} />}
    </BaseModal>
  )
}

const ProgressBar = ({ totalSteps, currentStep = 0 }) => (
  <div className="max-w-xl mx-auto my-4 border-b-2 pb-4">
    <div className="flex pb-3">
      {[...new Array(totalSteps)].map((_, index) => (
        <>
          <div className="flex-1"></div>
          <div className="flex-1">
            <div
              className={`w-10 h-10 ${
                index < currentStep ? "bg-[#B6CC43]" : "bg-white"
              } mx-auto rounded-full text-lg flex items-center justify-center border`}
            >
              <span className="text-center">{index + 1}</span>
            </div>
          </div>
          {index < totalSteps && (
            <div className="w-1/6 align-center items-center align-middle content-center flex">
              <div className="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                <div
                  className={`${
                    index + 1 < currentStep ? "bg-[#B6CC43]" : "bg-gray-300"
                  } text-xs leading-none py-0.5 text-center text-grey-darkest rounded`}
                ></div>
              </div>
            </div>
          )}
        </>
      ))}

      <div className="flex-1"></div>
    </div>
  </div>
)
