import * as Yup from "yup"

const dropdownShape = {
    label: Yup.string().required("Required"),
    value: Yup.string().required("Required")
}

export const SignupSchema = Yup.object().shape({
    firstName: Yup.string()
        .required("required")
        .max(40, "Too long")
        .min(2, "Too short"),
    lastName: Yup.string()
        .required("Champ requis")
        .max(40, "Trop long")
        .min(2, "Too short"),

    email: Yup.string().email().required(),
    cc: Yup.string().min(2).max(3).required("required"),
    phone: Yup.string().min(6).max(11).required(),
    organization: Yup.string().optional(),
    password: Yup.string().required().min(6, "Too Short").max(50, "Too long"),
    confirmPassword: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Passwords must match"
    ),
    agreeToTerms: Yup.bool().oneOf([true], "Please agree to terms and conditions")
})

export const newLandingPageSchema = Yup.object().shape({
    name: Yup.string().required(),
    url: Yup.string().url('Please enter valid url including https://', { allowLocal: true })
        .required('Please enter valid url including https://'),
})


export const LoginSchema = Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().required().min(6, "Too Short").max(50, "Too long"),
    rememberMe: Yup.boolean().required()
})

export const changePasswordSchema = Yup.object().shape({
    password: Yup.string().required().min(6, "Too Short").max(50, "Too long"),
    currentPassword: Yup.string()
        .required()
        .min(6, "Too Short")
        .max(50, "Too long"),
    confirmPassword: Yup.string()
        .required()
        .min(6, "Too Short")
        .max(50, "Too long")
})

export const forgetPasswordSchema = Yup.object().shape({
    email: Yup.string().email().required()
})

export const testPhaseSchema = Yup.object().shape({
    phase: Yup.number().min(1).required("Required")
})

export const newVerticalSchema = Yup.object().shape({
    name: Yup.string().required(),
    revenue_method: Yup.string().required(),

    // required if revenue_method is static
    revenue_percentage: Yup.number().when('revenue_method', {
        is: 'static',
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.notRequired()
    }),
})

export const assignVerticalToCampaginsSchema = Yup.object().shape({
    vertical: Yup.object()
        .shape({
            label: Yup.string().required(),
            value: Yup.string().required()
        })
        .required()
})

// export const verticalParamsSchema = Yup.object().shape.array().of({
//   name: Yup.string().min(4, "too short").required("Required"), // these constraints take precedence
//   salary: Yup.string().min(3, "cmon").required("Required") // these constraints take precedence
// })

export const verticalParamsSchema = Yup.object().shape({
    params: Yup.array()
        .of(
            Yup.object().shape({
                name: Yup.string().required("Required"), // these constraints take precedence
                token: Yup.string().required("Required") // these constraints take precedence
            })
        )
        .required("Required") // these constraints are shown if and only if inner constraints are satisfied
    // .min(1, "Minimum of 1 required")
})

export const createRuleCampaignSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, "Too short")
        .max(15, "Too long")
        .required("Required"),
    action: Yup.object().shape(dropdownShape).required("Required"),
    period: Yup.object().shape(dropdownShape).required("Required"),
    params: Yup.array()
        .of(
            Yup.object().shape({
                field: Yup.object().shape(dropdownShape).required("Required"),
                operator: Yup.object().shape(dropdownShape).required("Required"),
                value: Yup.string().required("Required")
            })
        )
        .required("Required") // these constraints are shown if and only if inner constraints are satisfied
        .min(1, "Minimum of 1 required")
})

export const createCampaignStepOne = Yup.object().shape({
    number_of_campaign: Yup.number().min(1).required(),
    number_of_adsets: Yup.number().min(1).required(),
    number_of_ads: Yup.number().min(1).required()
})

const facebook_page_config_shape = {
    page: Yup.object().shape(dropdownShape).required("Required"),
}
export const createCampaignStepTwo = Yup.object().shape({
    meta_facebook_page_config: Yup.object()
        .shape(facebook_page_config_shape)
        .required("Facebook Page is required"),
    meta_media_configs: Yup.array().of(
        Yup.object().shape({
            meta_media_images: Yup.array(),
            is_carousel: Yup.boolean().required("Carousel flag is required")
        })
    ),
    meta_text_configs: Yup.array().of(
        Yup.object().shape({
            text: Yup.string().required("Text is required")
        })
    ),
    meta_headline_configs: Yup.array().of(
        Yup.object().shape({
            text: Yup.string().required("Headline text is required")
        })
    ),
    meta_description_configs: Yup.array().of(
        Yup.object().shape({
            text: Yup.string().required("Description text is required")
        })
    ),
    extra_parameters: Yup.string().required()
})

export const createCampaign = Yup.object().shape({
    bid_strategy: Yup.object().required(),
    landing_page: Yup.object().shape(dropdownShape).optional(),
    budget: Yup.number().optional(),
    budget_spend_critaria: Yup.object().shape().optional(),
    name: Yup.string().required("Required"),
    objective: Yup.object().required(),
    special_ad_categories: Yup.object().required(),
    spend_cap: Yup.number().optional().nullable(),
    ad_account: Yup.object().shape(dropdownShape).required("Required"),
    budget_type: Yup.string().required(),
    start_date: Yup.string().optional(),
    start_time: Yup.string().optional()
})

export const createCampaignStepThree = Yup.object().shape({
    ad_set_name: Yup.string().required(),
    leads_place: Yup.string().required(),
    performance_goals: Yup.object().shape(dropdownShape).required("Required"),
    facebook: Yup.object().shape(dropdownShape).required("Required"),
    instagram: Yup.object().shape(dropdownShape).required("Required"),
    attribution: Yup.object().shape(dropdownShape).required("Required")
})

export const audienceTargetingValidationSchema = Yup.object().shape({
    // NEW

    adPlacementConfiguration: Yup.array()
        .of(
            Yup.object().shape({
                adPlacement: Yup.boolean(),
                devices: Yup.object().optional(),
                publisher_platforms: Yup.array(Yup.string()).required("Required"),
                facebook_positions: Yup.array(Yup.string()).required("Required"),
                instagram_positions: Yup.array(Yup.string()).required("Required"),
                audience_network_positions: Yup.array(Yup.string()).required(
                    "Required"
                ),
                messenger_positions: Yup.array(Yup.string()).required("Required")
            })
        )
        .required("Required") // these constraints are shown if and only if inner constraints are satisfied
        .min(1, "Minimum of 1 required"),
    audienceConfig: Yup.array()
        .of(
            Yup.object().shape({
                tabsList: Yup.array(),
                locations: Yup.array().required("Required"),
                targetingDetails: Yup.array().required("Required"),
                customAudience: Yup.object().shape().optional(),
                ageMax: Yup.object().shape(dropdownShape).required("Required"),
                ageMin: Yup.object().shape(dropdownShape).required("Required"),
                gender: Yup.string().optional(),
                language: Yup.object().shape(dropdownShape).required("Required")
            })
        )
        .required("Required") // these constraints are shown if and only if inner constraints are satisfied
        .min(1, "Minimum of 1 required"),
    optimizationAndDeliveryConfig: Yup.array()
        .of(
            Yup.object().shape({
                adOptimization: Yup.object().shape(dropdownShape).required("Required"),
                settings: Yup.object().shape(dropdownShape).required("Required"),
                deliveryType: Yup.object().shape(dropdownShape).required("Required"),
                budget_type: Yup.object().optional(),
                budget_price: Yup.string().optional()
            })
        )
        .required("Required") // these constraints are shown if and only if inner constraints are satisfied
        .min(1, "Minimum of 1 required")
})
export const createCampaignStepFour = Yup.object().shape({
    leads_place: Yup.string().required(),
    performance_goals: Yup.object().shape(dropdownShape).required("Required"),
    facebook: Yup.object().shape(dropdownShape).required("Required"),
    locations: Yup.string().required(),
    state: Yup.string().required(),
    city: Yup.string().required(),
    zipCode: Yup.string().required(),
    age_from: Yup.object().shape(dropdownShape).required("Required"),
    age_to: Yup.object().shape(dropdownShape).required("Required"),
    gender: Yup.string().required(),
    detailed_targeting: Yup.string().required(),
    languages: Yup.object().shape(dropdownShape).required("Required")
})

export const createCampaignStepFive = Yup.object().shape({
    ad_setup: Yup.string().required(),
    primary_text: Yup.string().required(),
    headline: Yup.string().required(),
    description: Yup.string().required(),
    website: Yup.string().url().required()
})

export const createUserSchema = Yup.object().shape({
    first_name: Yup.string()
        .required("required")
        .max(40, "Too long")
        .min(2, "Too short"),
    last_name: Yup.string()
        .required("Champ requis")
        .max(40, "Trop long")
        .min(2, "Too short"),

    email: Yup.string().email().required(),
    phone: Yup.string().min(6).max(13).required(),
    organization_role: Yup.object().shape(dropdownShape).required("Required")
})

export const JoinTeamSchema = Yup.object().shape({
    password: Yup.string().required().min(6, "Too Short").max(50, "Too long"),
    confirmPassword: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Passwords must match"
    )
})
