import { useFormik } from "formik";
import { BaseModal, Button, Dropdown } from "../../common";
import { AD_NAMING, AD_SET_NAMING } from "../../../utils/constants";

const AddDynamicTokensModal = ({ open = false, setOpen, handleChange, currentElement }) => {

    const submitHandler = async (values) => {
        setOpen(false)
        handleChange(values.naming)
    }

    const formik = useFormik({
        initialValues: {
            naming: ""
        },
        onSubmit: submitHandler
    })

    return (
        <BaseModal open={open} setOpen={setOpen} title={"Insert Token"}>
            <form onSubmit={formik.handleSubmit}>

                <div className="grid grid-cols-1 gap-4">
                    <Dropdown
                        placeholder="Select Token"
                        options={(currentElement && currentElement.includes('adSet')) ? AD_SET_NAMING : AD_NAMING}
                        onChange={value => formik.setFieldValue("naming", value)}
                        onBlur={formik.handleBlur}
                        value={formik.values.naming}
                        error={formik.errors.naming}
                        touched={formik.touched.naming}
                    />
                </div>
                <div className="mt-8 sm:mt-4 sm:flex sm:flex-row justify-end">
                    <Button
                        type="button"
                        className={"bg-[#CC4B43] mr-2 py-2 px-3 shadow-sm"}
                        onClick={() => {
                            setOpen(false)
                        }}
                    >
                        Close
                    </Button>
                    <Button
                        type="submit"
                        className={"bg-[#B6CC43] mr-2 py-2 px-3 shadow-sm"}
                    >
                        Add
                    </Button>
                </div>
            </form>
        </BaseModal>
    )
}


export default AddDynamicTokensModal