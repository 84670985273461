import { lazy } from "react";


const HomeDashboard = lazy(() => import('../pages/dashboard/dashboard/HomeDashboard'));
const Settings = lazy(() => import('../pages/dashboard/Settings'));
const Verticals = lazy(() => import('../pages/dashboard/Verticals'));
const LandingPages = lazy(() => import('../pages/dashboard/LandingPages'));
const CampaignsDashboard = lazy(() => import('../pages/dashboard/campaigns/CampaignsDashboard'));
const DraftCampaigns = lazy(() => import('../pages/dashboard/campaigns/DraftCampaigns'));
const CampaignStructure = lazy(() => import('../pages/dashboard/campaigns/creation/CampaignStructure'));
const AudienceTargeting = lazy(() => import('../pages/dashboard/campaigns/creation/AudienceTargeting'));
const AdCreation = lazy(() => import('../pages/dashboard/campaigns/creation/AdCreation'));
const AdReview = lazy(() => import('../pages/dashboard/campaigns/creation/AdReview'));
const AdvancePreview = lazy(() => import('../pages/dashboard/campaigns/creation/AdvancePreview'));
const RulesAndAutomation = lazy(() => import('../pages/dashboard/RulesAndAutomation'));
const UserManagement = lazy(() => import('../pages/dashboard/UserManagement'));
const CampaignTesting = lazy(() => import('../pages/dashboard/campaignTesting/CampaignTesting'));
const LogManagement = lazy(() => import('../pages/dashboard/LogManagement'));
const CampaignPerformance = lazy(() => import('../pages/dashboard/campaignPerformance/CampaignPerformance'));
const CreativeStudio = lazy(() => import('../pages/dashboard/CreativeStudio'));
const Adwords = lazy(() => import('../pages/dashboard/AdWords'));

export const authenticatedRoutes = [
    {
        path: "/dashboard",
        component: <HomeDashboard/>,
    },
    {
        path: "/dashboard/settings",
        component: <Settings/>
    },
    {
        path: '/dashboard/change-password',
        component: <Settings/>
    },
    {
        path: '/dashboard/offers',
        component: <Verticals/>
    },
    {
        path: '/dashboard/landing-page',
        component: <LandingPages/>
    },
    {
        path: '/dashboard/campagins',
        component: <CampaignsDashboard/>
    },
    {
        path: '/dashboard/drafts',
        component: <DraftCampaigns/>
    },
    {
        path: '/dashboard/campagins-structure/:campaignId?',
        component: <CampaignStructure/>
    },
    {
        path: '/dashboard/audience-targeting/:campaignId?',
        component: <AudienceTargeting/>
    },
    {
        path: '/dashboard/ad-creation/:campaignId?',
        component: <AdCreation/>
    },
    {
        path: '/dashboard/ad-review/:campaignId?',
        component: <AdReview/>
    },
    {
        path: '/dashboard/advance-review',
        component: <AdvancePreview/>
    },
    {
        path: '/dashboard/automation',
        component: <RulesAndAutomation/>
    },
    {
        path: '/dashboard/user-management',
        component: <UserManagement/>
    },
    {
        path: '/dashboard/testing',
        component: <CampaignTesting/>
    },
    {
        path: '/dashboard/logs',
        component: <LogManagement/>
    },
    {
        path: '/dashboard/campaign-performance',
        component: <CampaignPerformance/>
    },
    {
        path: '/dashboard/creative-studio',
        component: <CreativeStudio/>
    },
    {
        path: '/dashboard/adwords',
        component: <Adwords/>
    },
]

const Login = lazy(() => import('../pages/Login'));
const SignUp = lazy(() => import('../pages/SignUp'));
const ForgetPassword = lazy(() => import('../pages/ForgetPassword'));
const JoinTeam = lazy(() => import('../pages/JoinTeam'));

export const guestRoutes = [
    {
        path: '/login',
        component: <Login/>
    },
    {
        path: '/signup',
        component: <SignUp/>
    },
    {
        path: '/forget-password',
        component: <ForgetPassword/>
    },
    {
        path: '/join-team',
        component: <JoinTeam/>
    }
]