import { isEmpty } from "lodash";
import { createVerticalsAPI, updateLandingPagesAPI, updateVerticalsAPI } from "../../service";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { newVerticalSchema, verticalParamsSchema } from "../../helpers/validationSchema";
import { useEffect, useState } from "react";
import { BaseModal, Button, Checkbox, Input } from "../common";
import { BsTrash } from "react-icons/bs";

export const CreateVerticalModal = ({ open, setOpen, vertical }) => {
    const submitVerticalHandler = async (data) => {
        try {
            const { name, revenue_method, revenue_percentage } = data
            if (isEmpty(vertical)) {
                await createVerticalsAPI({ name, revenue_method, revenue_percentage })
            } else {
                await updateVerticalsAPI(vertical.id, { name, revenue_method, revenue_percentage })
            }

            toast.success("Vertical Created")
            formik.resetForm()
            setOpen(false)
        } catch (error) {
            toast.error("Something went wrong, make sure you have filled all the fields")
        } finally {
            formik.setSubmitting(false)
        }
    }

    const formik = useFormik({
        initialValues: { name: "", revenue_method: "dynamic", revenue_percentage: 0 },
        validationSchema: newVerticalSchema,
        onSubmit: submitVerticalHandler,
    })

    useEffect(() => {
        if (vertical) {
            formik.setFieldValue("name", vertical.name)
            formik.setFieldValue("revenue_method", vertical.revenue_method || "dynamic")
            formik.setFieldValue("revenue_percentage", vertical.revenue_percentage || 0)
        } else {
            formik.setFieldValue("name", "")
            formik.setFieldValue("revenue_method", "dynamic")
            formik.setFieldValue("revenue_method", 0)
        }
    }, [vertical])

    return (
        <BaseModal
            open={open}
            setOpen={setOpen}
            title={isEmpty(vertical) ? "Create Offer" : "Update Offer"}
        >
            <form onSubmit={formik.handleSubmit}>
                {/*className="flex items-center justify-center"*/}
                <div>
                    <Input
                        label="Offer Name"
                        placeholder="Offer Name"
                        name="name"
                        type="text"
                        required={true}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                        error={formik.errors.name}
                        touched={formik.touched.name}
                    />
                </div>
                <div className={""}>
                    Revenue Method
                    <Checkbox
                        title="Dynamic (based on postback calls)"
                        name="revenue_method"
                        type="radio"
                        value="dynamic"
                        checked={formik.values.revenue_method === "dynamic"}
                        onChange={() => formik.setFieldValue('revenue_method', 'dynamic')}
                    />
                    <Checkbox
                        title="Static (fix percentage of spend)"
                        name="revenue_method"
                        type="radio"
                        value="static"
                        checked={formik.values.revenue_method === "static"}
                        onChange={() => formik.setFieldValue('revenue_method', 'static')}
                        error={formik.errors.revenue_method}
                    />
                </div>
                {formik.values.revenue_method === "static" && (
                    <div>
                        <Input
                            label="Static Revenue Percentage (%)"
                            placeholder="eg, 10"
                            name="revenue_percentage"
                            type="number"
                            required={true}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.revenue_percentage}
                            error={formik.errors.revenue_percentage}
                            touched={formik.touched.revenue_percentage}
                        />
                    </div>
                )}
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row justify-end">
                    <Button
                        type="submit"
                        className={"bg-[#B6CC43] mr-2 py-2 px-3 shadow-sm"}
                    >
                        {isEmpty(vertical) ? "+ Create" : "Update"}
                    </Button>
                    <Button
                        type="button"
                        className={"bg-[#CC4B43] mr-2 py-2 px-3 shadow-sm"}
                        onClick={() => setOpen(false)}
                    >
                        Close
                    </Button>
                </div>
            </form>
        </BaseModal>
    )
}


export const AddParamatersVericalModal = ({ open, setOpen, verticalId, type, data, getAllData }) => {
    const [numberOfInputs, setNumberOfInputs] = useState(1)

    useEffect(() => {
        if (!open || !verticalId) {
            formik.setFieldValue("params", [{ name: "", token: "" }])
            setNumberOfInputs(1)
        } else {
            if (data && data.parameters) {

                const filteredParams = Object.entries(data.parameters).map((e) => ({ name: e[0], token: e[1] }))
                const paramsLenght = filteredParams.length === 0 ? 1 : filteredParams.length

                setNumberOfInputs(paramsLenght)
                formik.setFieldValue("params", filteredParams)
            } else {
                formik.setFieldValue("params", [{ name: "", token: "" }])
                setNumberOfInputs(1)
            }
        }
    }, [verticalId, open])

    const submitHandler = async ({ params: params }) => {
        try {
            if (type && type === 'landing') {
                const parameters = Object.assign({}, ...(params.map(item => ({ [item.name]: item.token }))))
                await updateLandingPagesAPI(verticalId, { parameters })
            } else {
                const parameters = Object.assign({}, ...(params.map(item => ({ [item.name]: item.token }))))
                await updateVerticalsAPI(verticalId, { parameters })
            }

            toast.success("Parameters Added")
            getAllData()
            formik.resetForm()
            // console.log("response", response)
            setOpen(false)
        } catch (error) {
            const message =
                (error?.response.data?.non_field_errors &&
                    error?.response.data?.non_field_errors[0]) ||
                "something went wrong"
            console.log("error?.response?", error.response)
            console.log("ERROR", error)
            toast.error(message)
        } finally {
            formik.setSubmitting(false)
        }
    }

    const handleRemove = (index) => {
        const temp = [...formik.values.params]
        temp.splice(index, 1)
        formik.setFieldValue("params", temp)
        setNumberOfInputs(old => --old)
    }

    const formik = useFormik({
        initialValues: {
            params: [{ name: "", token: "" }]
        },
        validationSchema: verticalParamsSchema,
        onSubmit: submitHandler
    })
    return (
        <BaseModal
            open={open}
            setOpen={setOpen}
            title="Add Parameters"
            className="sm:max-w-lg"
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="grid grid-cols-2 gap-x-2">
                    {[...new Array(numberOfInputs)].map((_, index) => (
                        <>
                            <div>
                                <Input
                                    placeholder="Parameter Name"
                                    name={`params[${index}].name`}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={
                                        formik.values.params && formik.values.params[index]?.name
                                    }
                                    error={
                                        formik.errors.params && formik.errors.params[index]?.name
                                    }
                                    touched={
                                        formik.touched.params && formik.touched.params[index]?.name
                                    }
                                />
                            </div>
                            <div className="flex justify-center items-start">
                                <Input
                                    placeholder="Parameter Token"
                                    name={`params[${index}].token`}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={
                                        formik.values.params && formik.values.params[index]?.token
                                    }
                                    error={
                                        formik.errors.params && formik.errors.params[index]?.token
                                    }
                                    touched={
                                        formik.touched.params && formik.touched.params[index]?.token
                                    }
                                />
                                <p className="ml-4 text-[#CC4B43]">
                                    <BsTrash
                                        size={22}
                                        onClick={() => handleRemove(index)}
                                        className="cursor-pointer mt-5"
                                    />
                                </p>
                            </div>
                        </>
                    ))}
                </div>

                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row justify-end">
                    <Button
                        className={"bg-[#00B2B4] mr-2 py-2 px-3 shadow-sm"}
                        onClick={() => {
                            formik.setFieldValue("params", [
                                ...formik.values.params,
                                { name: "", token: "" }
                            ])
                            setNumberOfInputs(old => ++old)
                        }}
                    >
                        + Add
                    </Button>
                    <Button
                        className={"bg-[#B6CC43] mr-2 py-2 px-3 shadow-sm"}
                        type="submit"
                    >
                        Save
                    </Button>
                    <Button
                        className={"bg-[#CC4B43] mr-2 py-2 px-3 shadow-sm"}
                        onClick={() => {
                            setNumberOfInputs(1)
                            formik.resetForm()
                            setOpen(false)
                        }}
                        type="button"
                    >
                        Close
                    </Button>
                </div>
            </form>
        </BaseModal>
    )
}
