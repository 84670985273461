import axios from "axios"
import queryString from 'query-string';

const BASE_URL = process.env.REACT_APP_BACKEND_URL || "https://app.mediascale.ai"
// const BASE_URL = "http://localhost:8000"
//const BASE_URL = "https://app.mediascale.ai"

axios.interceptors.request.use(config => {
    const token = localStorage.getItem("token")

    if (token) config.headers.Authorization = "Token " + token

    return config
})

axios.interceptors.response.use(
    response => response,
    error => {
        if (error.response?.status === 401) {
            localStorage.removeItem("token")
            if (window.location.pathname !== '/login') window.location.href = '/login';
        }
        // return new Error(error);
        return Promise.reject(error)
    }
)

export const login = body => {
    return axios.post(BASE_URL + `/api/v1/auth/login/`, body)
}
export const loginWithGoogle = body => {
    return axios.post(BASE_URL + `/api/v1/auth/google/`, body)
}
export const loginWithFacebook = body => {
    return axios.post(BASE_URL + `/api/v1/auth/facebook/`, body)
}
export const signup = body => {
    return axios.post(BASE_URL + `/api/v1/auth/sign-up/`, body)
}
export const forgetPawwordAPI = body => {
    return axios.post(BASE_URL + `/api/v1/reset-password/reset/`, body)
}
export const changePasswordAPI = body => {
    return axios.post(BASE_URL + `/api/v1/change-password/`, body)
}
export const getVerticalsAPI = params => {
    return axios.get(BASE_URL + `/api/v1/offers/`, { params })
}
export const createVerticalsAPI = body => {
    return axios.post(BASE_URL + `/api/v1/offers/`, body)
}
export const updateVerticalsAPI = (id, body) => {
    return axios.put(BASE_URL + `/api/v1/offers/${id}/`, body)
}
export const deleteVerticalsAPI = id => {
    return axios.delete(BASE_URL + `/api/v1/offers/${id}/`, {})
}
export const assignVerticalToCampagins = (id, body) => {
    return axios.put(BASE_URL + `/api/v1/campaign/${id}/`, body)
}

// const usersAPI = axios.create({
//   baseURL: SERVICE_URL,
//   headers: { Accept: "application/json", "Content-Type": "application/json" }
// })
// function getUserById(id) {
//   return usersAPI.get(`/api/v1/user/${id}/`)
// }
// function updateUserById(data, token) {
//   return usersAPI.patch(`/api/v1/user/${data.id}/`, data, {
//     headers: {
//       Authorization: `Token ${token}`
//     }
//   })
// }

export const createVerticalParamsAPI = body => {
    return axios.post(BASE_URL + `/api/v1/verticals_params/`, body)
}
export const getVerticalParamsAPI = id => {
    return axios.get(BASE_URL + `/api/v1/verticals_params/?id=${id}`)
}

export const getCampaginsAPI = params => {
    return axios.get(BASE_URL + `/api/v1/campaigns/`, { params })
}

export const deleteCampaignAPI = id => {
    return axios.delete(BASE_URL + `/api/v1/campaigns/${id}/`)
}

export const publishCampaignAPI = id => {
    return axios.post(BASE_URL + `/api/v1/campaigns/${id}/publish/`)
}

export const getProfileAPI = () => {
    return axios.get(BASE_URL + `/api/v1/users/self-profile/`)
}
export const getAllTimezones = () => {
    return axios.get(BASE_URL + `/api/v1/users/timezones/`)
}


export const getCampaignDetailAPI = id => {
    return axios.get(BASE_URL + `/api/v1/campaigns/${id}/`)
}
export const createCampaignAPI = body => {
    return axios.post(BASE_URL + `/api/v1/campaigns/`, body)
}
export const updateCampaignAPI = (id, body) => {
    return axios.put(BASE_URL + `/api/v1/campaigns/${id}/`, body)
}
export const updateCampaignDataAPI = (id, body) => {
    return axios.patch(BASE_URL + `/api/v1/campaigns/${id}/`, body)
}
export const fetchCampaignDataAPI = (id) => {
    return axios.get(BASE_URL + `/api/v1/campaigns/${id}/update-data/`)
}
export const searchCampaigns = search => {
    return axios.get(BASE_URL + `/api/v1/campaigns/search?search=${search}`)
}


export const fetchAdsetDataAPI = (id) => {
    return axios.get(BASE_URL + `/api/v1/remote-ad-sets/${id}/update-data/`)
}
export const getAdsetAPI = params => {
    return axios.get(BASE_URL + `/api/v1/remote-ad-sets/?${queryString.stringify(params)}`)
}
export const deleteAdsetAPI = id => {
    return axios.delete(BASE_URL + `/api/v1/remote-ad-sets/${id}/`)
}
export const updateAdsetAPI = (id, body) => {
    return axios.patch(BASE_URL + `/api/v1/remote-ad-sets/${id}/`, body)
}
export const searchAdSets = search => {
    return axios.get(BASE_URL + `/api/v1/remote-ad-sets/search?search=${search}`)
}


export const fetchAdsDataAPI = (id) => {
    return axios.get(BASE_URL + `/api/v1/remote-ads/${id}/update-data/`)
}
export const getAdCreativeAPI = params => {
    return axios.get(BASE_URL + `/api/v1/remote-ads/?${queryString.stringify(params)}`)
}
export const updateAdCreativeAPI = (id, body) => {
    return axios.patch(BASE_URL + `/api/v1/remote-ads/${id}/`, body)
}
export const searchAds = search => {
    return axios.get(BASE_URL + `/api/v1/remote-ads/search?search=${search}`)
}
export const deleteAdCreativeAPI = id => {
    return axios.delete(BASE_URL + `/api/v1/adcreative/${id}/`)
}


export const createRuleAPI = body => {
    return axios.post(BASE_URL + `/api/v1/rule/`, body)
}

export const getRulesAPI = (params) => {
    return axios.get(BASE_URL + `/api/v1/active-rules/`, { params })
}
export const updateRulesAPI = (id, body) => {
    return axios.patch(BASE_URL + `/api/v1/active-rules/${id}/`, body)
}
export const deleteRulesAPI = id => {
    return axios.delete(BASE_URL + `/api/v1/active-rules/${id}/`)
}
export const deleteBulkRulesAPI = body => {
    return axios.post(BASE_URL + `/api/v1/active-rules/bulk-delete/`, body)
}
export const getRuleTemplatesAPI = (params) => {
    return axios.get(BASE_URL + `/api/v1/rule-templates/`, { params })
}
export const getRuleLogsAPI = (params) => {
    return axios.get(BASE_URL + `/api/v1/rule-logs/`, { params })
}
export const createRuleTemplatesAPI = body => {
    return axios.post(BASE_URL + `/api/v1/rule-templates/`, body)
}
export const updateRuleTemplateDataAPI = (id, body) => {
    return axios.patch(BASE_URL + `/api/v1/rule-templates/${id}/`, body)
}
export const deleteRuleTemplatesAPI = id => {
    return axios.delete(BASE_URL + `/api/v1/rule-templates/${id}/`)
}
export const getUsersAPI = params => {
    // console.log('params', params);
    // const endpoint = params.name ? `/api/v1/users/?search=${params.name}&organization_role=${params.role}` : `/api/v1/users/`
    // const endpoint = params.role ? `/api/v1/users/?organization_role=${params.role}` : `/api/v1/users/`
    // return axios.get(BASE_URL + endpoint);
    // return axios.get(BASE_URL +`/api/v1/users/?search=${params.name}&organization_role=${params.role}`)
    return axios.get(BASE_URL + `/api/v1/users/`, { params })
}
export const postUsersAPI = body => {
    return axios.post(BASE_URL + `/api/v1/users/`, body)
}
export const updateUsersAPI = (id, body) => {
    return axios.put(BASE_URL + `/api/v1/users/${id}/`, body)
}
export const deleteUsersAPI = id => {
    return axios.delete(BASE_URL + `/api/v1/users/${id}/`)
}
export const getExpiredFacebookAccounts = () => {
    return axios.get(BASE_URL + `/api/v1/facebook-accounts/?expired=true`)
}
export const addFacebookAccount = body => {
    return axios.post(BASE_URL + `/api/v1/facebook-accounts/connect/`, body)
}
export const syncFacebookAdAccounts = id => {
    return axios.post(BASE_URL + `/api/v1/facebook-accounts/${id}/sync_ad_accounts/`)
}
export const authorizeFacebookAccount = body => {
    return axios.post(BASE_URL + `/api/v1/facebook-accounts/connect/`, body)
}
export const getFacebookAccounts = (params = {}) => {
    return axios.get(BASE_URL + `/api/v1/facebook-accounts/`, { params })
}
export const getFacebookAdAccountsAPI = () => {
    return axios.get(BASE_URL + `/api/v1/facebook-ad-accounts/`)
}
export const joinTeamResetPassword = body => {
    return axios.post(BASE_URL + `/api/v1/auth/password-reset/`, body)
}
export const removeFacebookAccount = id => {
    return axios.delete(BASE_URL + `/api/v1/facebook-accounts/${id}/`)
}
export const addFacebookAdAccount = (id, body) => {
    return axios.patch(BASE_URL + `/api/v1/facebook-ad-accounts/${id}/`, body)
}
export const removeFacebookAdAccount = id => {
    return axios.delete(BASE_URL + `/api/v1/facebook-ad-accounts/${id}/`)
}
export const facebookSearchLocation = (id, query) => {
    return axios.get(
        `${BASE_URL}/api/v1/campaigns/${id}/search/audience-geolocations/?search=${query}`
    )
}
export const facebookSearchDemographics = (id, query) => {
    return axios.get(
        `${BASE_URL}/api/v1/campaigns/${id}/search/audience-detailed-targetings/?search=${query}`
    )
}
export const facebookSearchLocale = (id, query) => {
    return axios.get(
        `${BASE_URL}/api/v1/campaigns/${id}/search/language/?search=${query}`
    )
}
export const getFacebookPixels = id => {
    return axios.get(`${BASE_URL}/api/v1/campaigns/${id}/ads-pixels/`)
}
export const getCustomAudience = id => {
    return axios.get(
        `${BASE_URL}/api/v1/campaigns/${id}/search/custom-audiences/`
    )
}
export const getTrackingScript = () => {
    return axios.get(BASE_URL + `/api/v1/tracking/scripts/`)
}

export const getFacebookPages = id => {
    return axios.get(BASE_URL + `/api/v1/campaigns/${id}/search/facebook-pages/`)
}

export const getSavedAudienceAPI = id => {
    return axios.get(BASE_URL + `/api/v1/campaigns/${id}/search/saved-audiences/`)
}

export const getInstantForm = (id, fbId) => {
    return axios.get(BASE_URL + `/api/v1/campaigns/${id}/search/${fbId}/instant-forms/`)
}

export const updateImagesAPI = (id, body) => {
    return axios.patch(BASE_URL + `/api/v1/campaigns/${id}/`, body, {
        headers: "multipart/form-data"
    })
}

export const getCreativeSelector = params => {
    return axios.get(BASE_URL + `/api/v1/creative-selector/`, { params })
}

export const getMetaTextConfigs = search => {
    return axios.get(BASE_URL + `/api/v1/meta-text-configs/`, {
        params: { search }
    })
}

export const getMetaDescriptionConfigs = search => {
    return axios.get(BASE_URL + `/api/v1/meta-description-configs/`, {
        params: { search }
    })
}

export const getMetaHeadlineConfigs = search => {
    return axios.get(BASE_URL + `/api/v1/meta-headline-configs/`, {
        params: { search }
    })
}
export const getMetaMediaImagesConfigs = search => {
    return axios.get(BASE_URL + `/api/v1/meta-media-images/`, {
        params: search
    })
}

export const pushAdstoFacebook = (id, body) => {
    return axios.post(BASE_URL + `/api/v1/campaigns/${id}/push-to-facebook/`, body)
}

export const getLandingPagesAPI = (params) => {
    return axios.get(BASE_URL + `/api/v1/landing-pages/`, { params })
}

export const createLandingPagesAPI = body => {
    return axios.post(BASE_URL + `/api/v1/landing-pages/`, body)
}
export const updateLandingPagesAPI = (id, body) => {
    return axios.patch(BASE_URL + `/api/v1/landing-pages/${id}/`, body)
}
export const deleteLandingPagesAPI = id => {
    return axios.delete(BASE_URL + `/api/v1/landing-pages/${id}/`, {})
}
export const getLandingPageNotificationsAPI = () => {
    return axios.get(BASE_URL + `/api/v1/landing-pages/notifications/`)
}
export const getStatsAPI = (params) => {
    return axios.get(BASE_URL + `/api/v1/stats/summary/?${queryString.stringify(params)}`)
}
export const updateSelfConfigAPI = (body) => {
    return axios.patch(BASE_URL + `/api/v1/fe-config/self-config/`, body)
}
export const getSelfConfigAPI = () => {
    return axios.get(BASE_URL + `/api/v1/fe-config/self-config/`)
}
export const getLeadStatsAPI = (params) => {
    return axios.get(BASE_URL + `/api/v1/stats/leads-stats/?${queryString.stringify(params)}`)
}
export const getTestCampaginsAPI = params => {
    return axios.get(BASE_URL + `/api/v1/test-phases/`, { params })
}
export const addTestModule = body => {
    return axios.post(BASE_URL + `/api/v1/test-modules/`, body)
}
export const addTestPhase = body => {
    return axios.post(BASE_URL + `/api/v1/test-phases/`, body)
}
export const updateTestPhase = (id, body) => {
    return axios.patch(BASE_URL + `/api/v1/test-phases/${id}/`, body)
}
export const assignTemplatesToCampagins = (id, body) => {
    return axios.post(BASE_URL + `/api/v1/rule-templates/${id}/assign-entities/`, body)
}
export const getAllClickTracksAPI = (params) => {
    return axios.get(BASE_URL + `/api/v1/click-tracks/`, { params })
}
export const getAllConversionsAPI = (params) => {
    return axios.get(BASE_URL + `/api/v1/postback-leads/`, { params })
}
export const assignBulkTemplatesToCampagins = (body) => {
    return axios.post(BASE_URL + `/api/v1/rule-templates/bulk-assign-entities/`, body)
}
export const createDuplicateCampaign = (id) => {
    return axios.get(BASE_URL + `/api/v1/campaigns/${id}/create-duplicate/`)
}
