import moment from "moment";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { getCreativeSelector, getMetaMediaImagesConfigs } from "../../../service";
import { BaseModal, Button, Dropdown, Input, Pagination, Spinner } from "../../common";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import ManageDashboardDataTableColumnsModal from "./ManageDashboardDataTableColumnsModal";
import { toast } from "react-toastify";

const SelectExistingAdCreativeValuesModal = ({ open = false, setOpen, creativeType, selectedInput, addSelection }) => {
    const { offerList, campaigns: campaignList } = useSelector(({ common }) => common)

    const [campaigns, setCampaigns] = useState([])
    const [offers, setOffers] = useState([]);
    const [selectedCreatives, setSelectedCreatives] = useState([])
    const [loading, setLoading] = useState(false)
    const [presetOptions, setPresetOptions] = useState([])
    const [totalItems, setTotalItems] = useState(0)
    const [searchText, setSearchText] = useState("")
    const [currentPage, setCurrentPage] = useState(0)
    const [showManageColumnsModal, setShowManageColums] = useState(false)
    const [creativeTypeTitle, setCreativeTypeTitle] = useState(creativeType)
    const [creativeColumns, setCreativeColumns] = useState([])
    const [allColumns, setColumns] = useState([
        // { label: "ID", value: "id", isActive: false },
        { label: "Creative", value: "creative", isActive: false },
        { label: "Impressions", value: "impressions", isActive: false },
        { label: "Spend", value: "spend", isActive: false },
        { label: "Revenue", value: "ms_revenue", isActive: false },
        { label: "Profit", value: "ms_profit", isActive: false },
        { label: "Clicks", value: "ms_clicks", isActive: false },
        { label: "CTR", value: "ms_ctr", isActive: false },
        { label: "ROI", value: "ms_roi", isActive: false },

        // { label: "Submitted Leads", value: "ms_submitted_leads", isActive: false },
        // { label: "Sold Leads", value: "ms_sold_leads", isActive: false },
        // { label: "Conversion Rate", value: "ms_conversion_rate", isActive: false },
        // { label: "Sold Rate", value: "ms_sold_rate", isActive: true },
        // { label: "EPC", value: "ms_epc", isActive: true },
        // { label: "CPC", value: "ms_lpvc", isActive: true },
    ])

    useEffect(() => {
        setTimeout(() => console.clear(), 1000)
    }, [])

    // useEffect(() => {
    //     handleFilterData({})
    // }, [currentPage])

    useEffect(() => {
        if (open === true) {
            prepareSelectExistingValuesModal(selectedInput.index, selectedInput.type)
        } else {
            formikselector.resetForm()
            setSelectedCreatives([])
            setPresetOptions([])
            setTotalItems(0)
            setLoading(false)
        }
    }, [open])

    useEffect(() => {
        updateCreativeColumns()
    }, [allColumns])

    useEffect(() => {
        if (offerList) {
            setOffers(
                offerList.map(item => ({ label: item.name, value: item.id }))
            )
        }
    }, [offerList])

    useEffect(() => {
        if (campaignList) {
            setCampaigns(
                campaignList.map(item => ({ label: item.name, value: item.id }))
            )
        }
    }, [campaignList])

    const handleFilterData = async (values) => {
        try {
            console.log({ values })
            let current = (currentPage > 0) ? currentPage : 0
            let params = { offset: current, limit: 10 }

            if (creativeTypeTitle) {
                params.fetch = (creativeTypeTitle === 'Description') ? 'description' : (creativeTypeTitle === 'Headline') ? 'headline' : (creativeTypeTitle === 'Primary Text') ? 'text' : ''
            }

            if (values.offer) {
                params.offer_ids = values.offer.value
            }
            if (values.campaign) {
                params.campaign_ids = values.campaign.value
            }
            if (values.start_date) {
                params.insights_start_date = values.start_date
            }
            if (values.end_date) {
                params.insights_end_date = values.end_date
            }

            setLoading(true)
            const { data } = await getCreativeSelector(params)
            setPresetOptions(data)
            setTotalItems(data.length)
            setLoading(false)
        } catch (error) {

        }
    }

    const formikselector = useFormik({
        initialValues: {
            offer: {},
            campaign: {},
            start_date: moment().subtract(1, 'month').format('YYYY-MM-DD'),
            end_date: moment().format('YYYY-MM-DD')
        },
        onSubmit: handleFilterData
    })

    // write test cases for this
    const getExistingValues = async (type, text = searchText) => {
        try {
            let current = (currentPage > 0) ? currentPage : 0
            let params = { offset: current, limit: 10 }
            if (formikselector.values.start_date) {
                params.insights_start_date = formikselector.values.start_date
            }
            if (formikselector.values.end_date) {
                params.insights_end_date = formikselector.values.end_date
            }

            switch (type) {
                case "meta_text_configs":
                    setCreativeTypeTitle("Primary Text")
                    return await getCreativeSelector({ ...params, fetch: 'text' })
                case "meta_description_configs":
                    setCreativeTypeTitle("Description")
                    return await getCreativeSelector({ ...params, fetch: 'description' })
                case "meta_headline_configs":
                    setCreativeTypeTitle("Headline")
                    return await getCreativeSelector({ ...params, fetch: 'headline' })
                case "getMetaMediaImagesConfigs":
                    setCreativeTypeTitle("Media")
                    return await getMetaMediaImagesConfigs(text)
            }
        } catch (e) {
            console.log("error", e)
        }
    }

    const prepareSelectExistingValuesModal = async (index, type, search = '') => {
        try {
            formikselector.resetForm()
            // await formikselector.setFieldValue(`start_date`, moment().format('YYYY-MM-DD'))
            // await formikselector.setFieldValue(`end_date`, moment().format('YYYY-MM-DD'))
            setSelectedCreatives([])
            setLoading(true)
            setOpen(true)
            const { data } = await getExistingValues(type, search)
            setPresetOptions(data)
            setTotalItems(data.length)
            setLoading(false)
        } catch (e) {
            console.log("error", e)
            setLoading(false)
        }
    }

    const handleSorting = (selectedColumn, sortDirection) => {
        let sortedData = []
        if (sortDirection === 'desc') {
            sortedData = presetOptions.sort((a, b) => a[selectedColumn.id] - b[selectedColumn.id])
        } else {
            sortedData = presetOptions.sort((a, b) => b[selectedColumn.id] - a[selectedColumn.id])
        }
        setPresetOptions(() => {
            console.log('changing data')
            return sortedData
        })
    }

    const updateCreativeColumns = () => {
        const newCreativeColumns = []
        allColumns.forEach((column, index) => {
            switch (column.value) {
                case 'creative':
                    newCreativeColumns.push({
                        name: "Creative",
                        id: 'creative',
                        sortable: false,
                        minWidth: '350px',
                        omit: column.isActive,
                        selector: row => <span>{row.creative}</span>,
                        wrap: 'no'
                    })
                    break
                case 'impressions':
                    newCreativeColumns.push({
                        name: "Impressions",
                        id: 'impressions',
                        sortable: true,
                        minWidth: '180px',
                        omit: column.isActive,
                        selector: row => <span>{row.impressions}</span>
                    })
                    break
                case 'spend':
                    newCreativeColumns.push({
                        name: "Spend",
                        id: 'spend',
                        sortable: true,
                        omit: column.isActive,
                        selector: row => <span>${row.spend}</span>
                    })
                    break
                case 'ms_revenue':
                    newCreativeColumns.push({
                        name: "Revenue",
                        id: 'ms_revenue',
                        sortable: true,
                        omit: column.isActive,
                        selector: row => <span>${row.ms_revenue}</span>
                    })
                    break
                case 'ms_profit':
                    newCreativeColumns.push({
                        name: "Profit",
                        id: 'ms_profit',
                        sortable: true,
                        omit: column.isActive,
                        selector: row => <span>${row.ms_profit}</span>
                    })
                    break
                case 'ms_clicks':
                    newCreativeColumns.push({
                        name: "Clicks",
                        id: 'ms_clicks',
                        sortable: true,
                        omit: column.isActive,
                        selector: row => <span>{row.ms_clicks}</span>
                    })
                    break
                case 'ms_ctr':
                    newCreativeColumns.push({
                        name: "CTR",
                        id: 'ms_ctr',
                        sortable: true,
                        omit: column.isActive,
                        selector: row => <span>${row.ms_ctr}</span>
                    })
                    break
                case 'ms_roi':
                    newCreativeColumns.push({
                        name: "ROI",
                        id: 'ms_roi',
                        sortable: true,
                        omit: column.isActive,
                        selector: row => <span>{row.ms_roi}%</span>
                    })
                    break
            }
        })
        setCreativeColumns(newCreativeColumns)
    }

    const resetFilters = () => formikselector.resetForm()

    const customStyles = {
        headRow: {
            style: {
                fontWeight: '600',
                paddingTop: '0.875rem',
                paddingBottom: '0.875rem',
                paddingRight: '0.75rem',
                fontSize: '0.875rem',
                lineHeight: '1.25rem',
                boxShadow: '5px 1px 5px 1px #5E84C24F'
            }
        },
        rows: {
            style: {
                boxShadow: '10px 10px 20px 5px #5E84C24F'
            }
        }
    }

    return (
        <>
            <BaseModal
                open={open}
                setOpen={setOpen}
                title={`Creative Selector - ${creativeTypeTitle}`}
                className="xl:max-w-screen-xl 3xl:max-w-screen-3xl"
            >
                <div className="mt-4">
                    <form onSubmit={formikselector.handleSubmit}>
                        <div className="grid grid-cols-1 md:grid-cols-6 lg:grid-cols-6 lg:gap-5 mb-2 items-end mt-4">
                            <div className="block">
                                <span className="font-semibold text-gray-500">Offers</span>
                                <Dropdown
                                    placeholder="Select"
                                    classBox="bg-white"
                                    options={offers}
                                    onChange={value => formikselector.setFieldValue("offer", value)}
                                    onBlur={formikselector.handleBlur}
                                    value={formikselector.values.offer}
                                    error={formikselector.errors.offer}
                                    touched={formikselector.touched.offer}
                                />
                            </div>
                            <div className="block">
                                <span className="font-semibold text-gray-500">Campaigns</span>
                                <Dropdown
                                    placeholder="Select"
                                    classBox="bg-white"
                                    options={campaigns}
                                    onChange={value => formikselector.setFieldValue("campaign", value)}
                                    onBlur={formikselector.handleBlur}
                                    value={formikselector.values.campaign}
                                    error={formikselector.errors.campaign}
                                    touched={formikselector.touched.campaign}
                                />
                            </div>
                            <div className="block">
                                <span className="font-semibold text-gray-500">Start Date</span>
                                <Input
                                    placeholder="Start Date"
                                    type="date"
                                    className="bg-white"
                                    name="start_date"
                                    onChange={(e) => formikselector.setFieldValue("start_date", e.target.value)}
                                    onBlur={formikselector.handleBlur}
                                    value={formikselector.values.start_date}
                                    error={formikselector.errors.start_date}
                                    touched={formikselector.touched.start_date}
                                />
                            </div>
                            <div className="block">
                                <span className="font-semibold text-gray-500">End Date</span>
                                <Input
                                    placeholder="End Date"
                                    type="date"
                                    className="bg-white"
                                    name="end_date"
                                    onChange={(e) => formikselector.setFieldValue("end_date", e.target.value)}
                                    onBlur={formikselector.handleBlur}
                                    value={formikselector.values.end_date}
                                    error={formikselector.errors.end_date}
                                    touched={formikselector.touched.end_date}
                                />
                            </div>
                            <button className="px-2 py-2 my-4 button-gradient rounded-xl font-bold text-white" button="submit">
                                Apply
                            </button>
                            <button className="px-2 py-2 my-4 bg-[#B6CC43] rounded-xl font-bold text-white" onClick={() => resetFilters()}>
                                Reset Filters
                            </button>
                            {/*<button className="px-2 py-2 my-4 bg-[#B6CC43] rounded-xl font-bold text-white" onClick={() => setShowManageColums(true)}>*/}
                            {/*    Manage Columns*/}
                            {/*</button>*/}
                        </div>
                    </form>

                    <div className="relative py-3">
                        <DataTable
                            columns={creativeColumns}
                            data={presetOptions}
                            responsive={true}
                            progressPending={loading}
                            customStyles={customStyles}
                            progressComponent={<Spinner/>}
                            selectableRows
                            onSelectedRowsChange={({ selectedRows }) => setSelectedCreatives(selectedRows)}
                            className='react-dataTable'
                            defaultSortFieldId={2}
                            defaultSortAsc={false}
                            onSort={(selectedColumn, sortDirection) => handleSorting(selectedColumn, sortDirection)}
                            highlightOnHover
                            fixedHeader
                            fixedHeaderScrollHeight="700px"
                            persistTableHead
                        />
                    </div>
                    <div className="flex items-center justify-between px-4 py-3 sm:px-6 mt-5 ">
                        <Button className="px-7 py-2 bg-[#B6CC43] rounded-xl ml-3 w-fit" onClick={() => addSelection(selectedCreatives)}>
                            Add Selected
                        </Button>
                        {/*<Pagination*/}
                        {/*    totalPages={Math.ceil(totalItems / 10)}*/}
                        {/*    currentPage={currentPage}*/}
                        {/*    setCurrentPage={setCurrentPage}*/}
                        {/*    offset={10}*/}
                        {/*/>*/}
                    </div>
                </div>
            </BaseModal>

            {/*<ManageDashboardDataTableColumnsModal*/}
            {/*    open={showManageColumnsModal}*/}
            {/*    setOpen={setShowManageColums}*/}
            {/*    allColumns={allColumns}*/}
            {/*    setColumns={setColumns}*/}
            {/*/>*/}
        </>
    )
}

export default SelectExistingAdCreativeValuesModal;