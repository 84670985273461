import { useState } from "react";
import { createRuleAPI } from "../../../service";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { createRuleCampaignSchema } from "../../../helpers/validationSchema";
import { BaseModal, Button, Dropdown, Input } from "../../common";
import { BsTrash } from "react-icons/bs";

const CreateRuleForCampaignsModal = ({ open, setOpen, campaigns = [] }) => {
    const [numberOfInputs, setNumberOfInputs] = useState(1)

    const submitHandler = async ({ action: { label: action }, period: { label: period }, params, name }) => {
        try {
            let finalParams = params.map(
                ({
                     field: { label: field },
                     operator: { label: operator },
                     value
                 }) => ({
                    value: parseInt(value),
                    operator,
                    field
                })
            )
            console.log("FORM PARSM", {
                name,
                action,
                period: parseInt(period),
                conditions: finalParams,
                campaign: campaigns[0].id
            })
            const finalCampaigns = campaigns.map(({ id }) => id)
            await createRuleAPI({
                name,
                action,
                period: parseInt(period),
                conditions: finalParams,
                campaigns: finalCampaigns
            })
            toast.success("Rule Created")
            setNumberOfInputs(1)
            formik.resetForm()
            setOpen(false)
        } catch (error) {
            const message =
                (error?.response.data?.non_field_errors &&
                    error?.response.data?.non_field_errors[0]) ||
                "something went wrong"
            console.log("error?.response?", error.response)
            console.log("ERROR", error)
            toast.error(message)
        } finally {
            formik.setSubmitting(false)
        }
    }

    const formik = useFormik({
        initialValues: {
            params: [{ field: {}, operator: {}, value: "" }],
            action: {},
            period: {},
            name: ""
        },
        validationSchema: createRuleCampaignSchema,
        onSubmit: submitHandler
    })
    return (
        <BaseModal
            open={open}
            setOpen={setOpen}
            title="Create New Rule"
            className="sm:max-w-xl"
        >
            <form onSubmit={formik.handleSubmit}>
                <Input
                    placeholder="Rule Name"
                    name="name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    error={formik.errors.name}
                    touched={formik.touched.name}
                />
                <div className="my-4">
                    <p className="font-bold text-base">Apply Rule to:</p>
                    <ul className="list-disc list-inside mt-3">
                        {campaigns.map(({ name }) => (
                            <li>{name}</li>
                        ))}
                    </ul>
                </div>
                <Dropdown
                    placeholder="Action"
                    options={[
                        { label: "Turn on Adset", value: "Turn on Adset" },
                        { label: "Turn off Adset", value: "Turn off Adset" },
                        { label: "Increase Daily budget by", value: "Increase Daily budget by" },
                        { label: "Decrease Daily budget by", value: "Decrease Daily budget by" },
                        { label: "Increase Lifetime budget by", value: "Increase Lifetime budget by" },
                        { label: "Send notification", value: "Send notification" }
                    ]}
                    name="action"
                    onChange={value => formik.setFieldValue("action", value)}
                    onBlur={formik.handleBlur}
                    value={formik.values.action}
                    error={formik.errors.action}
                    touched={formik.touched.action}
                />
                <Dropdown
                    placeholder="Period"
                    options={[
                        { label: "Today", value: "Today" },
                        { label: "Yesterday", value: "Yesterday" },
                        { label: "Last 3 Days", value: "Last 3 Days" },
                        { label: "Last 7 Days", value: "Last 7 Days" },
                        { label: "Last 14 Days", value: "Last 14 Days" },
                        { label: "Last 30 Days", value: "Last 30 Days" },
                        { label: "Last 3 Days, Including Today", value: "Last 3 Days, Including Today" },
                        { label: "Last 7 Days, Including Today", value: "Last 7 Days, Including Today" },
                        { label: "Last 14 Days, Including Today", value: "Last 14 Days, Including Today" },
                        { label: "Last 30 Days, Including Today", value: "Last 30 Days, Including Today" }
                    ]}
                    name="period"
                    onChange={value => formik.setFieldValue("period", value)}
                    onBlur={formik.handleBlur}
                    value={formik.values.period}
                    error={formik.errors.period}
                    touched={formik.touched.period}
                />
                <div className="py-5">
                    <p className="font-semibold">Conditions</p>
                    <p className="text-sm">All of the following match</p>
                    <div className="grid grid-cols-3 gap-x-2 mr-5">
                        {[...new Array(numberOfInputs)].map((_, index) => (
                            <>
                                <Dropdown
                                    placeholder="Field"
                                    options={[
                                        { label: "Amount Spent", value: "Amount Spent" },
                                        { label: "Lifetime Spent", value: "Lifetime Spent" },
                                        { label: "Conversions", value: "Conversions" },
                                        { label: "Cost Per Conversion", value: "Cost Per Conversion" },
                                        { label: "Current Time", value: "Current Time" },
                                        { label: "Clicks", value: "Clicks" },
                                        { label: "CPM", value: "CPM" },
                                        { label: "CPC", value: "CPC" },
                                        { label: "CTR", value: "CTR" }
                                    ]}
                                    name={`params[${index}].field`}
                                    onChange={value =>
                                        formik.setFieldValue(`params[${index}].field`, value)
                                    }
                                    onBlur={formik.handleBlur}
                                    value={
                                        formik.values.params &&
                                        formik.values?.params[index] &&
                                        formik.values?.params[index].field
                                    }
                                    error={
                                        formik.errors?.params &&
                                        formik.errors?.params[index] &&
                                        formik.errors?.params[index].field
                                    }
                                    touched={
                                        formik.touched?.params &&
                                        formik.touched?.params[index] &&
                                        formik.touched?.params[index].field
                                    }
                                />
                                <Dropdown
                                    placeholder="Operator"
                                    options={[
                                        { label: "<", value: "Less Than" },
                                        { label: ">", value: "Greater Than" },
                                        { label: "<=", value: "Less than or Equal to" },
                                        { label: ">=", value: "Greater than or Equal to" },
                                        { label: "=", value: "Equal to" },
                                        { label: "≬", value: "Between" }
                                    ]}
                                    name={`params[${index}].operator`}
                                    onChange={value =>
                                        formik.setFieldValue(`params[${index}].operator`, value)
                                    }
                                    onBlur={formik.handleBlur}
                                    value={
                                        formik.values.params &&
                                        formik.values.params[index] &&
                                        formik.values.params[index].operator
                                    }
                                    error={
                                        formik.errors.params &&
                                        formik.errors.params[index] &&
                                        formik.errors.params[index].operator
                                    }
                                    touched={
                                        formik.touched.params &&
                                        formik.touched.params[index] &&
                                        formik.touched.params[index].operator
                                    }
                                />
                                <Input
                                    placeholder="Value"
                                    name={`params[${index}].value`}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    type="number"
                                    min="0"
                                    value={
                                        formik.values.params && formik.values.params[index]?.value
                                    }
                                    error={
                                        formik.errors.params && formik.errors.params[index]?.value
                                    }
                                    touched={
                                        formik.touched.params && formik.touched.params[index]?.value
                                    }
                                />
                            </>
                        ))}
                    </div>
                    <p className="absolute right-3 bottom-36 text-[#CC4B43]">
                        {numberOfInputs > 1 && (
                            <BsTrash
                                size={24}
                                onClick={() => {
                                    formik.values.params.pop()
                                    formik.setFieldValue("params", [...formik.values.params])
                                    setNumberOfInputs(old => --old)
                                }}
                                className="cursor-pointer"
                            />
                        )}
                    </p>
                    <div className="flex justify-end mr-3">
                        <Button
                            className={"bg-[#00B2B4] mr-2 py-2 px-3 shadow-sm"}
                            onClick={() => {
                                formik.setFieldValue("params", [
                                    ...formik.values.params,
                                    { field: {}, operator: {}, value: "" }
                                ])
                                setNumberOfInputs(old => ++old)
                            }}
                        >
                            + Add
                        </Button>
                    </div>
                </div>

                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row justify-end">
                    <Button
                        className={"bg-[#B6CC43] mr-2 py-2 px-3 shadow-sm"}
                        type="submit"
                    >
                        Save
                    </Button>
                    <Button
                        className={"bg-[#CC4B43] mr-2 py-2 px-3 shadow-sm"}
                        onClick={() => {
                            setNumberOfInputs(1)
                            formik.resetForm()
                            setOpen(false)
                        }}
                        type="button"
                    >
                        Close
                    </Button>
                </div>
            </form>
        </BaseModal>
    )
}


export default CreateRuleForCampaignsModal