// ** Initial State
const initialState = {
    isFetching: false,
    stats: null,
    statsParams: {},
    leads: null,
    campaigns: [],
    landingPages: [],
    offerList: [],
    facebookAdAccounts: [],
}

const common = (state = initialState, { type, payload, isFetching, params = {} }) => {
    switch (type) {
        case 'GET_STATS_SUMMARY':
            return { ...state, stats: payload, isFetching: isFetching, statsParams: params }
        case 'GET_LEAD_STATS':
            return { ...state, leads: payload, isFetching: isFetching }
        case 'GET_CAMPAIGN_LISTS':
            return { ...state, campaigns: payload, isFetching: isFetching }
        case 'GET_ALL_LANDING':
            return { ...state, landingPages: payload, isFetching: isFetching }
        case 'GET_ALL_OFFERS':
            return { ...state, offerList: payload, isFetching: isFetching }
        case 'GET_FB_AD_ACCOUNTS':
            return { ...state, facebookAdAccounts: payload, isFetching: isFetching }
        default:
            return { ...state }
    }
}

export default common