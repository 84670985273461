import { toast } from "react-toastify";
import { assignBulkTemplatesToCampagins } from "../../../service";
import { useFormik } from "formik";
import { BaseModal, Button, DropdownMultiple } from "../../common";

 const ApplyTemplateToCampaignsModal = ({ open = false, setOpen, data, selectedRows, type }) => {

    const submitHandler = async ({ template }) => {
        try {
            if (template && template.length === 0) {
                toast.error("Please select templates")
                return false
            }

            const temps = template.map((items) => items.value)
            const params = {
                rule_template_ids: temps
            }

            if (type && type === "campaigns") {
                params.meta_campaign_ids = selectedRows.map((items) => items.id)
            } else if (type && type === "adsets") {
                params.ad_set_ids = selectedRows.map((items) => items.id)
            } else if (type && type === "ads") {
                params.ad_ids = selectedRows.map((items) => items.id)
            }

            await assignBulkTemplatesToCampagins(params)
            toast.success("Applied Templates")
            formik.resetForm()
            setOpen(false)
        } catch (error) {
            const message =
                (error?.response.data?.non_field_errors &&
                    error?.response.data?.non_field_errors[0]) ||
                "something went wrong"
            console.log("error?.response?", error.response)
            console.log("ERROR", error)
            toast.error(message)
        } finally {
            formik.setSubmitting(false)
        }
    }

    const formik = useFormik({
        initialValues: {
            template: []
        },
        onSubmit: submitHandler
    })

    const handleChange = (value) => {
        if (value && value.length > 0) {
            formik.setFieldValue("template", value)
        } else {
            formik.setFieldValue("template", [])
        }
    }

    return (
        <BaseModal open={open} setOpen={setOpen} title={"Apply Template"} modalSize="max-w-xl">
            <form onSubmit={formik.handleSubmit}>
                <div className="flex-col">
                    <div className="grid grid-cols-1 gap-4 py-5">
                        <DropdownMultiple
                            placeholder="Select Template"
                            options={data}
                            onChange={value => handleChange(value)}
                            onBlur={formik.handleBlur}
                            value={formik.values.template}
                            error={formik.errors.template}
                            touched={formik.touched.template}
                        />
                    </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row justify-end">
                    <Button
                        type="button"
                        className={"bg-[#CC4B43] mr-2 py-2 px-3 shadow-sm"}
                        onClick={() => {
                            setOpen(false)
                        }}
                    >
                        Close
                    </Button>
                    <Button
                        type="submit"
                        className={"bg-[#B6CC43] mr-2 py-2 px-3 shadow-sm"}
                    >
                        Save
                    </Button>
                </div>
            </form>
        </BaseModal>
    )
}


export default ApplyTemplateToCampaignsModal